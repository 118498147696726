import React from "react";
import { ContentHeader, Banner } from "./style";
import logo from "../../assets/images/logo.png";
import banner from "../../assets/images/banner-vagas-esgotadas.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { isMobile } from "react-device-detect";

export default function HeaderHome() {
  return (
    <>
      <ContentHeader>
        <a
          href="https://instagram.com/ligandoideiasredacao"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
          @ligandoideiasredacao
        </a>
        <img src={logo} alt="Ligando Ideias Redação" />

        <a
          href="https://api.whatsapp.com/send?phone=3488777797"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon />
          (34) 9 8877-7797
        </a>
      </ContentHeader>
      {isMobile ? (
        <Banner style={{ backgroundImage: `url(${banner})` }}></Banner>
      ) : (
        <img
          src={banner}
          alt="Relembre tudo antes do Enem - Ligando Ideias Redação"
        />
      )}
    </>
  );
}
