import styled from "styled-components";

export const ContainerHome = styled.div`
  min-height: 100vh;
  background-color: #fafafa;
`;

export const ContentHome = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media only screen and (max-width: 601px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;
